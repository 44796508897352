<!--评论-->
<template>
  <div id="detailstable">
    <table>
      <tr class="row1">
        <td>
          <my-info :showData="comment[0]" @transferData="getTransferData0"></my-info>
        </td>
        <td >
          <my-info :showData="comment[3]" @transferData="getTransferData3"></my-info>
        </td>
      </tr>
      <tr class="row2">
        <td> 
          <my-info :showData="comment[1]" @transferData="getTransferData1"></my-info>
        </td>
        <td>
          <my-info :showData="comment[4]" @transferData="getTransferData4"></my-info>
        </td>
      </tr>
      <tr class="row3">
        <td>
          <my-info :showData="comment[2]" @transferData="getTransferData2"></my-info>
        </td>
        <td>
          <my-info :showData="comment[5]" @transferData="getTransferData5"></my-info>
        </td>
      </tr>            
    </table>
  </div>
</template>

<script>
import baseInfo from "./baseInfo";
export default {
  props: {
    commentIds: {
      type:Array, 
      default:function () {
        return [ "","","","",""]
      },
    }
  },
  data() {
    return {      
      comment: [
        { id: 1, content: this.commentIds[0] },
        { id: 2, content: this.commentIds[1] },
        { id: 3, content: this.commentIds[2] },
        { id: 4, content: this.commentIds[3] },
        { id: 5, content: this.commentIds[4] },
        { id: 6, content: this.$t('lightDetails.a12') }
      ],
      commentContent:["","","","","",""],
    }    
  },
  methods: {
    getTransferData0(msg){
      this.commentContent[0]=msg;
      this.$emit('transferData',this.commentContent);//返回数据至父组件
    },
    getTransferData1(msg){
      this.commentContent[1]=msg;
      this.$emit('transferData',this.commentContent);//返回数据至父组件
    },
    getTransferData2(msg){
      this.commentContent[2]=msg;
      this.$emit('transferData',this.commentContent);//返回数据至父组件
    },
    getTransferData3(msg){
      this.commentContent[3]=msg;
      this.$emit('transferData',this.commentContent);//返回数据至父组件
    },
    getTransferData4(msg){
      this.commentContent[4]=msg;
      this.$emit('transferData',this.commentContent);//返回数据至父组件
    },
    getTransferData5(msg){
      this.commentContent[5]=msg;
      this.$emit('transferData',this.commentContent);//返回数据至父组件
    },
  },

  components: {
    "my-info": baseInfo,
  }
};
</script>
<style scoped>
#detailstable {
  width: 100%;
  margin-bottom: 40px;
  white-space: nowrap;
  overflow: hidden;
}
table {
  width: 100%;
  border-left: 1px solid #dedfdf;  
  border-right: 1px solid #dedfdf;
}
.tabletitle td {
  color: #3e3a39;
  font-size: 14px;
  font-weight: 500;
  padding: 16px;
}
.tablecontent td {
  padding: 10px 16px;
  font-size: 14px;
}
.top {
  border-top: 1px solid #dedfdf;
}
/* .table-button {
  width: 120px;
} */
img {
  width: 36px;
  margin-right: 15px;
}
tr.row1 td{width:calc(100%/2)}
</style>