<!-- 电能设备信号点详情页 -->
<template>
  <div class="energySignalDetails" v-loading="loading" :element-loading-text="$t('alarm.a0')">
    <!-- 顶栏 -->
    <div class="header">
        <!-- 左上角头部开关 <span>{{ signalName }}</span>  -->
        <div class="header-header">
            <img :src="getImages" />
            
            <a-input :class="namecls" v-model="signalName" id="signalName" name="signalName" :title="$t('energy.enedata401')" :placeholder="$t('energy.enedata401')"></a-input>
            <a-tooltip :title="$t('energy.enedata401')" placement="right" style="color: #7682ce;">
                <a-icon type="question-circle-o" />
            </a-tooltip>   
        </div>
        <!-- 返回上一级箭头 -->
        <a-icon
          type="close" 
          :style="{ fontSize: '20px', color: '#7682CE' }"
          @click="goDack"
        />
    </div>
    <div class="main">
        <!-- 曲线图 -->
        <div id="chart-main" class="content">
          <div :id="signalChart" :style="{width: '100%', height: '400px'}"></div>
        </div>

        <!-- 当前信号值 -->
        <!-- 统计面板 累计-->
        <div class="statistics" v-if="signalType===23">
          <!-- 计量 -->
          <div class="measure">
            <span class="measure-header">{{$t('energy.enedata105')}}({{measure.engineeringUnits}})</span>
            <div class="measure-main">
              <p class="measure-main-t">{{measure.doublePresentValue}}</p>
              <span class="measure-main-b">{{measure.changeDatetime}}</span>
              <div class="measure-footer">
                <div>
                  <p class="measure-footer-p">{{measure.presentValue}}</p>
                  <span class="measure-footer-s">{{$t('energy.enedata106')}}</span>
                </div>
                <div>
                  <p class="measure-footer-p">{{measure.cumulativeValue}}</p>
                  <span class="measure-footer-s">{{$t('energy.enedata107')}}</span>
                </div>
                <div>
                  <p class="measure-footer-p">{{measure.monthlyCount}}</p>
                  <span class="measure-footer-s">{{$t('energy.enedata110')}}</span>
                </div>
              </div>
            </div>
          </div>
          <!-- 时数据和日数据 -->
          <div class="statistics-realTime">
            <div class="circle">
              <p class="circle-p">{{measure.hourlyCount}}</p>
              <span class="circle-s">{{$t('energy.enedata108')}}</span>
            </div>
            <div class="circle">
              <p class="circle-p">{{measure.dailyCount}}</p>
              <span class="circle-s">{{$t('energy.enedata109')}}</span>
            </div>
          </div>
          <ul class="operate">
            <li class="operate-li">
              <span>{{$t('energy.enedata111')}}</span>
              <div>
                <a-switch v-model="measure.maintenance" @change="handleChange(0)"/>
              </div>
            </li>
            <li class="operate-li border">
                <span>{{$t('energy.enedata112')}}：{{measure.coefficient}}{{measure.engineeringUnits}}</span>
              <div class="operate-li-div">
                <span>{{$t('energy.enedata013')}}：</span>
                <a-select v-model="disp" style="width: 100px">
                    <a-select-option
                        v-for="(itme, index) in measure.displayTypes"
                        :key="index" v-model="itme.no" >
                        {{ itme.text }}
                    </a-select-option>
                </a-select>
              </div>
            </li>
            <li class="operate-li">
              <span>{{$t('energy.enedata113')}}：{{measure.engineeringUnits}}</span>
              <div class="operate-li-div">
                <span>{{$t('energy.enedata114')}}：</span>
              <a-select v-model="sound" style="width: 100px">
                    <a-select-option
                        v-for="(itme, index) in measure.alarmSounds"
                        :key="index" v-model="itme.no" >
                        {{ itme.text }}
                    </a-select-option>
                </a-select>
              </div>
            </li>
          </ul>
        </div>
        <!-- 统计面板  模拟 -->
        <div class="statistics" v-if="signalType===0 || signalType===1 || signalType===2">
          <!-- 计量 -->
          <div class="measure">
            <span class="measure-header">{{$t('energy.enedata105')}}({{measure.engineeringUnits}})</span>
            <div class="measure-main">
              <!-- <p class="measure-main-t">{{measure.presentValue}}</p>    -->
              <span :class="colorFlag==0?'measure-main-t':colorFlag==1? 'measure-main-red': 'measure-main-green'">{{measure.presentValue}}</span>          
            </div>
            <span class="measure-main-b">{{measure.changeDatetime}}</span>
            <div class="measure-footer">
              <!-- 取值范围 -->
                  {{$t('energy.enedata131')}}：{{measure.lowValueRange}}~{{measure.highValueRange}}
              </div>
          </div>
          <!-- 时数据和日数据 -->
          <div class="statistics-realTime">
            
          </div>
          <ul class="operate">
            <li class="operate-li">
              <span> {{$t('energy.enedata111')}}</span>
              <div>
                <a-switch v-model="measure.maintenance" @change="handleChange(0)"/>
              </div>
            </li>
            <li class="operate-li border">
                <span> {{$t('energy.enedata113')}}：{{measure.engineeringUnits}}</span>
            </li>
            <li class="operate-li">
              
              <span> {{$t('energy.enedata114')}}：</span>
              <a-select v-model="sound" style="width: 150px">
                    <a-select-option
                        v-for="(itme, index) in measure.alarmSounds"
                        :key="index" v-model="itme.no" >
                        {{ itme.text }}
                    </a-select-option>
                </a-select>
            
            </li>
          </ul>
        </div>
        <!-- 统计面板  数字  -->
        <div class="statistics" v-if="signalType===3 || signalType===4 || signalType===5">
        
          <!-- 计量 -->
          <div class="measure">
            <!-- 状态值 -->
            <span class="measure-header">{{$t('energy.enedata141')}}</span>
            <div class="measure-main">
              <p class="measure-main-t">{{measure.status}}</p>         
            </div>
            <span class="measure-main-b">{{measure.changeDatetime}}</span>
            <div class="measure-footer" v-if="signalType===4">
                <span> {{$t('energy.enedata151')}}：</span>
                <a-select v-model="binaryOpea" style="width: 100px">
                  <a-select-option
                      v-for="(itme, index) in measure.statusList"
                      :key="index" v-model="itme.no" >
                      {{ itme.text }}
                  </a-select-option>
                </a-select>
                <a-button type="primary" @click="handleBinaryAction">
                    {{$t('energy.enedata023')}}
                </a-button>
            </div>
            <div class="measure-footer" v-else>
            </div>
          </div>

          <ul class="operate">
            <li class="operate-li">
              <!-- 维护登记 -->
              <span>{{$t('energy.enedata111')}}</span>
              <div>
                <a-switch v-model="measure.maintenance" @change="handleChange(0)"/>
              </div>
            </li>
            <li class="operate-li">
              <span>{{$t('energy.enedata142')}}：{{measure.on}}</span><span>{{$t('energy.enedata143')}}：{{measure.off}}</span>
            </li>
            <li class="operate-li">
              
              <span>{{$t('energy.enedata114')}}：</span>
              <a-select v-model="sound" style="width: 150px">
                    <a-select-option
                        v-for="(itme, index) in measure.alarmSounds"
                        :key="index" v-model="itme.no" >
                        {{ itme.text }}
                    </a-select-option>
                </a-select>
            
            </li>
          </ul>
        </div>
        <!-- 统计面板  多态  -->
        <div class="statistics-13" v-if="signalType==13 || signalType==14 || signalType===19">
        
        <!-- 计量 -->
        <a-row style="width: 100%;">
            <a-col :span="9">
                <div class="measure-13">
                    <span class="measure-header">{{$t('energy.enedata141')}}</span>
                    <div class="measure-main">
                          <p class="measure-main-t-13">{{measure.multiStateValue}}</p>         
                    </div>
                    <span class="measure-main-b">{{measure.changeDatetime}}</span>
                    <div class="measure-footer" v-if="signalType==14">
                      <span> {{$t('energy.enedata151')}}：</span>
                      <a-select v-model="binaryOpea" style="width: 100px">
                        <a-select-option
                            v-for="(itme, index) in measure.multiStateList"
                            :key="index" v-model="itme.no" >
                            {{ itme.text }}
                        </a-select-option>
                      </a-select>
                      <a-button type="primary" @click="handleBinaryAction">
                          {{$t('energy.enedata023')}}
                      </a-button>
                    </div>
                    <div v-else class="measure-footer">
                        <a-select v-model="measure.presentValue" style="width: 100px" :disabled="!measure.maintenance">
                        <a-select-option
                            v-for="(itme, index) in measure.multiStateList"
                            :key="index" v-model="itme.no" >
                            {{ itme.text }}
                        </a-select-option>
                      </a-select>
                    </div>
                </div>

            </a-col>
            <a-col :span="15">
                <ul class="operate-13">
                  <li class="operate-li-13">
                    <span style="white-space: nowrap;">{{$t('energy.enedata111')}}：</span>
                    <div>
                      <a-switch v-model="measure.maintenance" @change="handleChange(0)"/>
                    </div>
                  </li>
                  <li class="operate-li-13">
                    <span style="white-space: nowrap;">{{$t('energy.enedata152')}}：</span>
                    <div>
                        <a-button
                              v-for="(itme, index) in measure.multiStateList"
                              :key="index" v-model="itme.no" >
                              {{ itme.text }}
                          </a-button>
                    </div>
                  </li>
                    <li class="operate-li-13">
                      &nbsp;
                    </li>
                    <li class="operate-li-13">
                      &nbsp;
                    </li>
                </ul>

            </a-col>
        </a-row>
        

          
        </div>

        <!-- 监视项 -  模拟  -->
        <div class="box"  v-if="signalType===0 || signalType===1 || signalType===2">
            <div class="monitor-title-analog">
                <span>{{$t('energy.enedata115')}}</span>
                <div class="monitor-title-analog-icon">
                  <a-tooltip :title="$t('energy.enedata434')" placement="right" style="color: #7682ce;">            
                    <a-icon type="right-square" theme="twoTone" @click="jump"></a-icon>
                  </a-tooltip> 
                </div>
            </div>
            <!-- 上下限值监视 - 模拟 -->
            <ul class="monitor-ul">
              <li class="monitor-li">
                  <div class="grid">
                  <a-checkbox v-model="monitor.maxMonitor" @change="handleChange(1)" id="maxMonitor">{{$t('energy.enedata116')}}</a-checkbox>
                  </div>
                  <div class="grid-two">
                  <span>{{$t('energy.enedata132')}}</span>
                  </div>
                  <div :class="highLimitcls">
                  <a-input class="input-width" v-model="monitor.high" :disabled="!monitor.maxMonitor"></a-input>
                  </div>
              </li>
              <li class="monitor-li">
                  <div class="grid">
                  <a-checkbox v-model="monitor.minMonitor"  @change="handleChange(2)" id="minMonitor">{{$t('energy.enedata117')}}</a-checkbox>
                  </div>
                  <div class="grid-two">
                  <span>{{$t('energy.enedata133')}}</span>
                  </div>
                  <div :class="lowLimitcls">
                  <a-input class="input-width" v-model="monitor.low" :disabled="!monitor.minMonitor"></a-input>
                  </div>
              </li>
              <li class="monitor-li">
                  <div class="grid"></div>
                  <div class="grid-two">
                  <span>{{$t('energy.enedata134')}}</span>
                  </div>
                  <div :class="intvlcls">
                  <a-input class="input-width" v-model="monitor.fixed"></a-input>
                  </div>
                  <!-- 0~（上限值-下限值）范围内设置 -->
                  <span>{{$t('energy.enedata135')}}</span>
              </li>
            </ul>
        </div>

        <!-- 维护监视 - 数字 -->
        <div class="box"  v-if="signalType===3 || signalType===4 || signalType===5">
            <div class="monitor-title">
              <!-- 维护监视 -->
                <span> {{$t('energy.enedata144')}}</span>
            </div>
            <!-- 维护监视 - 数字 -->
            <ul class="monitor-ul" v-if="signalType==4 && cos!=null">
              <!-- 维护监视 - COS  BINARY-OUT -->
              <li class="monitor-li-binary">
                  <div class="box-grid">
                    <!-- 条件不一致监视 -->
                    <a-checkbox v-model="cos.cosCondition"  @change="handleChange(16)" id="cosCondition">
                      {{$t('energy.enedata153')}}
                    </a-checkbox>
                    <!-- 监控时间 -->
                    <span>{{$t('energy.enedata154')}}&nbsp;&nbsp;{{cos.time}}</span>
                  </div>
                  <div class="box-grid-low">
                    <!-- 设置值 -->
                    <span class="box-grid-s">{{$t('energy.enedata155')}}</span>
                    <a-input class="input-width" v-model="cos.time" :min="0" :max="255"  :disabled="!cos.cosCondition"></a-input>
                    <span>{{$t('energy.enedata121')}}</span>
                  </div>
              </li>

            </ul>
            <ul class="monitor-ul" v-if="signalType==3 && monitor.alertTime!=null">
              <!-- 维护监视 - 故障  BINARY-INPUTT -->
              <li class="monitor-li-binary">
                  <div class="box-grid">
                    <a-checkbox v-model="monitor.alertTime.over"  @change="handleChange(14)" id="alertTimeOver">
                      <!-- 故障报警时间超过故障监视报警时间 -->
                      {{$t('energy.enedata156')}}
                    </a-checkbox>
                  
                    <a-input class="input-width" v-model="monitor.alertTime.overCount" :disabled="!monitor.alertTime.over"></a-input>
                    <span>{{$t('energy.enedata157')}}</span>
                  </div>
                  <div class="box-grid-low">
                    <span class="box-grid-s">{{$t('energy.enedata132')}}</span>
                    <a-input class="input-width" v-model="monitor.alertTime.max" :disabled="!monitor.alertTime.over"></a-input>
                    <span>{{$t('energy.enedata157')}}</span>
                  </div>
              </li>
              <li class="monitor-li-binary">
                  <div class="box-grid">
                    <a-checkbox v-model="monitor.alertTimes.over"  @change="handleChange(15)" id="alertTimesOver">
                      <!-- 故障报警次数超过故障监视报警次数 -->
                      {{$t('energy.enedata158')}}
                    </a-checkbox>
                  
                    <a-input-number class="input-width" v-model="monitor.alertTimes.overCount" :disabled="!monitor.alertTimes.over" :min="0" :max="50"></a-input-number>
                    <span>{{$t('energy.enedata159')}}</span>
                  </div>
                  <div class="box-grid-low">
                    <span class="box-grid-s">{{$t('energy.enedata133')}}</span>
                    <a-input-number class="input-width" v-model="monitor.alertTimes.max" :disabled="!monitor.alertTimes.over" :min="0" :max="50"></a-input-number>
                    <!-- <a-input-number></a-input-number> -->
                    <span>{{$t('energy.enedata159')}}</span>
                  </div>
              </li>
            </ul>
            <ul class="monitor-ul"  v-if="signalType==3 && monitor.cumulativeTime!=null">
              <!-- 维护监视 - 状态  BINARY-INPUTT -->
              <li class="monitor-li-binary">
                <div class="box-grid">
                    <a-checkbox v-model="monitor.cumulativeTime.over"  @change="handleChange(11)" id="cumulativeTimeOver">
                      <!-- 累计运行时间超过监控点运行时间 -->
                      {{$t('energy.enedata160')}}
                    </a-checkbox> 
                    <a-input class="input-width" v-model="monitor.cumulativeTime.overCount" :disabled="!monitor.cumulativeTime.over"></a-input>
                    <span>{{$t('energy.enedata157')}}</span>
                  </div>
                  <div class="box-grid-low">
                    <span class="box-grid-s">{{$t('energy.enedata133')}}</span>
                    <a-input class="input-width" v-model="monitor.cumulativeTime.max" :disabled="!monitor.cumulativeTime.over"></a-input>
                    <span>{{$t('energy.enedata157')}}</span>
                  </div>
              </li>
              <li class="monitor-li-binary">
                <div class="box-grid">
                    <a-checkbox v-model="monitor.continuousTime.over"  @change="handleChange(12)" id="continuousTimeOver">
                      <!-- 连续运行时间超过监视连续运行时间 -->
                      {{$t('energy.enedata161')}}
                    </a-checkbox>
                    <a-input class="input-width" v-model="monitor.continuousTime.overCount" :disabled="!monitor.continuousTime.over"></a-input>
                    <span>{{$t('energy.enedata157')}}</span>
                  </div>
                  <div class="box-grid-low">
                    <span class="box-grid-s">{{$t('energy.enedata133')}}</span>
                    <a-input class="input-width" v-model="monitor.continuousTime.max" :disabled="!monitor.continuousTime.over"></a-input>
                    <span>{{$t('energy.enedata157')}}</span>
                  </div>
              </li>
              <li class="monitor-li-binary">
                  <div class="box-grid">
                    <a-checkbox v-model="monitor.statusChangeTimes.over"  @change="handleChange(13)" id="statusChangeTimesOver">
                      <!-- 状态变化次数超过监视状态变化次数 -->
                      {{$t('energy.enedata162')}}
                    </a-checkbox>
                  
                    <a-input class="input-width" v-model="monitor.statusChangeTimes.overCount" :disabled="!monitor.statusChangeTimes.over" min="0" max="50"></a-input>
                    <span>{{$t('energy.enedata159')}}</span>
                  </div>
                  <div class="box-grid-low">
                    <span class="box-grid-s">{{$t('energy.enedata133')}}</span>
                    <a-input class="input-width" v-model="monitor.statusChangeTimes.max" :disabled="!monitor.statusChangeTimes.over"></a-input>
                    <span>{{$t('energy.enedata159')}}</span>
                  </div>
              </li>
            </ul>
        </div>
        <!-- 上下限值监视 - 累计 -->
        <div class="box"  v-if="signalType===23">
            <div class="monitor-title">
                <span>{{$t('energy.enedata115')}}</span>
                <span>{{$t('energy.enedata122')}} &nbsp; {{$t('energy.enedata123')}} &nbsp; {{monitor.pulseRate}} &nbsp; </span>
            </div>
            <!-- 上下限值监视 - 累计 -->
            <ul class="monitor-ul">
              <li class="monitor-li">
                  <div class="grid">
                    <a-checkbox v-model="monitor.maxMonitor"  @change="handleChange(1)" id="maxMonitor">{{$t('energy.enedata116')}}</a-checkbox>
                  </div>
                  <div class="grid-two">
                    <span>{{$t('energy.enedata118')}}</span>
                  </div>
                  <div :class="highLimitcls">
                    <a-input class="input-width" v-model="monitor.highLimit" :disabled="!monitor.maxMonitor"></a-input>
                  </div>
              </li>
              <li class="monitor-li">
                  <div class="grid">
                    <a-checkbox v-model="monitor.minMonitor" @change="handleChange(2)" id="minMonitor">{{$t('energy.enedata117')}}</a-checkbox>
                  </div>
                  <div class="grid-two">
                    <span>{{$t('energy.enedata119')}}</span>
                  </div>
                  <div :class="lowLimitcls">
                  <a-input class="input-width" v-model="monitor.lowLimit" :disabled="!monitor.minMonitor"></a-input>
                  </div>
              </li>
              <li class="monitor-li">
                  <div class="grid"></div>
                  <div class="grid-two">
                  <span>{{$t('energy.enedata120')}}</span>
                  </div>
                  <div :class="intvlcls">
                  <a-input class="input-width" v-model="monitor.limitMonitoringInterval"></a-input>
                  </div>
                  <span>{{$t('energy.enedata121')}}</span>
              </li>
            </ul>
        </div>


        <!-- 趋势间隔设置 -->
        <div class="box" v-if="measure.pvTrendIdentifier">
            <div class="monitor-title">
                <span>{{$t('energy.enedata547')}}</span>
                <!-- <span>趋势记录信息</span> -->
            </div>
            <!-- 趋势间隔设置 -->
            <ul class="monitor-ul">
              <li class="monitor-li"> 
                  <a-descriptions  size="middle">
                    <!-- <a-descriptions-item label="记录生成间隔时间"> -->
                    <a-descriptions-item  class="has-error" :label="$t('energy.enedata548')">
                        <a-input-number v-model="measure.pvTrendInterval" :min="1" :max="3600" />
                        <span>{{$t('energy.enedata121')}}</span><!-- 秒-->
                    </a-descriptions-item>
                    <!-- <a-descriptions-item label="趋势读取状态"> -->
                    <a-descriptions-item :label="$t('energy.enedata552')">
                      {{measure.trendLastFlag?$t('energy.enedata553'):$t('energy.enedata554')}}
                      <!-- '读取中':'停止读取' -->
                    </a-descriptions-item>
                    <!-- <a-descriptions-item label="最近读取编号"> -->
                    <a-descriptions-item :label="$t('energy.enedata556')">
                      {{measure.trendLastId}}
                    </a-descriptions-item>
                    <!-- <a-descriptions-item label="读取间隔时间"> -->
                    <a-descriptions-item :label="$t('energy.enedata549')">
                      <span>{{measure.trendSkip}}</span> 
                      <!-- <span>分钟</span> -->
                      <span>{{$t('energy.enedata551')}}</span>
                    </a-descriptions-item>

                    <!-- <a-descriptions-item label="最近读取时间"> -->
                    <a-descriptions-item :label="$t('energy.enedata555')">
                      {{measure.trendLastExecute}}
                    </a-descriptions-item>
                    <!-- <a-descriptions-item label="最新趋势记录时间"> -->
                    <a-descriptions-item :label="$t('energy.enedata550')">
                      {{measure.trendLastDate}}
                    </a-descriptions-item>
                    <!-- <a-descriptions-item label="Official">
                      $60.00
                    </a-descriptions-item> -->
                  </a-descriptions>
              </li>          
            </ul>
        </div>


        <!--  评论注册项 -->
        <div class="box">
          <div class="box-title ">
              {{$t('energy.enedata124')}}
          </div>
            <div class="register">
                <ul class="register-ul">
                    <li class="register-li">
                    <div class="register-li-item">
                      <span class="input-cmt-sn"> 1</span>
                      <a-input :class="comment1cls" v-model="comment[0]" id="comment1" name="comment1" :title="comment[0]==''?$t('energy.enedata126'):comment[0]" :placeholder="$t('energy.enedata126')"></a-input>
                    </div>
                    </li>
                    <li class="register-li">
                    <div class="register-li-item">
                        <span class="input-cmt-sn"> 2</span>
                      <a-input :class="comment2cls" v-model="comment[1]" id="comment2" name="comment2" :title="comment[1]==''?$t('energy.enedata126'):comment[1]" :placeholder="$t('energy.enedata126')"></a-input>
                    </div>
                    </li>
                    <li class="register-li">
                    <div class="register-li-item">
                      <span class="input-cmt-sn"> 3</span>
                      <a-input :class="comment3cls" v-model="comment[2]" id="comment3" name="comment3" :title="comment[2]==''?$t('energy.enedata126'):comment[2]" :placeholder="$t('energy.enedata126')"></a-input>
                    </div>
                    </li>
                </ul>
                <ul class="register-ul">
                    <li class="register-li">
                    <div class="register-li-item">
                        <span class="input-cmt-sn"> 4</span>
                      <a-input :class="comment4cls" v-model="comment[3]" id="comment4" name="comment4" :title="comment[3]==''?$t('energy.enedata126'):comment[3]" :placeholder="$t('energy.enedata126')"></a-input>
                    </div>
                  
                    </li>
                    <li class="register-li">
                    <div class="register-li-item">
                        <span class="input-cmt-sn"> 5</span>
                        <a-input :class="comment5cls" v-model="comment[4]" id="comment5" name="comment5" :title="comment[4]==''?$t('energy.enedata126'):comment[4]" :placeholder="$t('energy.enedata126')"></a-input>
                    </div>
                    
                    </li>
                    <li class="register-li">
                    <p class="register-li-item-p">{{$t('energy.enedata125')}}</p>
                    <a-button type="primary" ghost @click="clearComment">{{$t('energy.enedata127')}}</a-button>
                    </li>
                </ul>
            </div>
        </div>

        <div class="footer">
          <a-button type="primary" ghost @click="handleFlush">{{$t('energy.enedata128')}}</a-button>
          <a-button type="primary" @click="handleEdit">{{$t('energy.enedata129')}}</a-button>
        </div>
    </div>
  </div>
</template>

<script>
import commontTable from "../../lightDetails/components/lightTable";
import moment from "moment";
import { getEnergySignalDetail,updateEnergyEquipmentDetail,updatePointSignalPresentValue } from "../../../api/energy";

export default {
  data() {
    return {
      loading: false,
      title: "",
      signal:null,
      signalName:"",
      signalType:"",
      disp:"",
      sound:"",
      measure:"",
      monitor:"",
      colorFlag:0,
      cos:null,
      comment:[],
      intvlcls:'grid',
      lowLimitcls:'grid',
      highLimitcls:'grid',
      namecls:'ename',
      comment1cls:'input-cmt',
      comment2cls:'input-cmt',
      comment3cls:'input-cmt',
      comment4cls:'input-cmt',
      comment5cls:'input-cmt',
      binaryOpea:'',
    };
  },
 async mounted() {
    this.handleLoadData();
  },
  methods: {
    setTrendData(){
      this.signal.trendData = {
          name: "",
          xunit: "5分",
          yunit: "kWh",
          categories: [ ],
          data: [ ]
      }
    },
    //页面跳转
    jump(){  
      this.$router.push({
          path: "/homepage/management/warning/equipment",
          query: {
            // id:this.cardContent.id,
            siteId: this.siteId,
          },
      });
    },
    initial(){
      this.intvlcls='grid';
      this.lowLimitcls='grid';
      this.highLimitcls='grid';
      this.namecls='ename';
      this.comment1cls='input-cmt';
      this.comment2cls='input-cmt';
      this.comment3cls='input-cmt';
      this.comment4cls='input-cmt';
      this.comment5cls='input-cmt';
    },
    refreshChart(){
      let type = this.$route.query.kind;
      this.drawLine(this.signal.trendData, type,this.signal.tagPoint,this.signal.signalType,this.monitor);
        //实现自适应部分
      window.addEventListener("resize", () => this.chartResize());
    },
    vailString(name,value,min,max){
        let regEn = /[`~!@#$%^&*+<>?:",\/;']/im;
        let code = '';

        let err = 0;
        if(''===value){
          code = "energy.enedata307";
          err = 1;
        } else if(value.length<min || value.length>max){
          let msg = this.$t("energy.enedata308");
          msg = msg.replace("{text}",name);
          msg = msg.replace("{star}",min);
          msg = msg.replace("{end}",max);
          err = 1;
           return {err: err,msg:msg};
        } else if(regEn.test(value)) {
           code = "energy.enedata309";
           err = 1;
        } else {
           err = 0;
           return {err: err,msg:''};
        }

        let msg = this.$t(code);
        msg = msg.replace("{text}",name);
        return {err: err,msg:msg};
    },
    checkNumber(name,val,min,max){
        let num = new Number(val);
        let err = 0;
        if(isNaN(num)){
          err = 1;
        }
        if(val*1 < min*1 || val*1 > max*1){
           err = 2;
        }
        let emsg = '';
        if(err!=0){
            if(err==2){
                emsg = this.$t('energy.enedata372');
                emsg = emsg.replace('{name}',name);
                emsg = emsg.replace('{min}',min);
                emsg = emsg.replace('{max}',max);
            }else{
                emsg = this.$t('energy.enedata373');
                emsg = emsg.replace('{name}',name);
            }
        }
        return {err:err,msg:emsg};
    },
    goDack() {
      this.$router.go(-1);
    },
    handleBinaryAction(){
      console.log("handleBinaryAction");
      if(this.binaryOpea!=''){
          let sitegrId  = this.$store.getters.sitegrId;
          let clientId  = this.$store.getters.clientId;
          let siteId    = parseInt(sessionStorage.getItem("siteid"));
          let type      = this.$route.query.kind*1;
          let signalId  = this.$route.query.signalId;
          let param={
              clientId: clientId,
              sitegrId: sitegrId,
              siteId: siteId,
              tenantId: 0,
              signalId: signalId,
              signalType: type,
              presentValue: this.binaryOpea
            };
          this.loading = true;
          updatePointSignalPresentValue(param)
            .then((res) => {
                if (res.errorCode == "00") {
                    this.$message.success(res.msg);
                }else {
                    this.$message.error(res.msg);
                }        
                this.loading = false;
            }).catch((err) => {
                console.log(err);
                this.loading = false;
            });
      }else{

      }
    },
    handleEdit() {
      console.log("handleEdit");

      let emsg = '';
      let err = 0;

      let sitegrId  = this.$store.getters.sitegrId;
      let clientId  = this.$store.getters.clientId;
      let siteId    = parseInt(sessionStorage.getItem("siteid"));

      let pgrId     = this.$route.query.pgrId;
      let iconNo    = this.$route.query.iconNo;
      let type      = this.$route.query.kind*1;
      let signalId  = this.$route.query.signalId;

      this.namecls = 'ename';
      let errInfo = this.vailString(this.$t('energy.enedata306'),this.signalName,0,50);
      if(errInfo.err>0){
          err = errInfo.err;
          this.namecls = 'ename vail-error';
          this.$message.error(errInfo.msg);
      }


      let cname = this.$t('energy.enedata124');
      for (let i = 0; i < this.comment.length; i++) {
          if(this.comment[i]!=''){
              errInfo = this.vailString(cname +(i+1),this.comment[i],0,200);
              if(errInfo.err>0){
                err = errInfo.err;
                if(i==0)this.comment1cls='input-cmt vail-error';
                else if(i==1)this.comment2cls='input-cmt vail-error';
                else if(i==2)this.comment3cls='input-cmt vail-error';
                else if(i==3)this.comment4cls='input-cmt vail-error';
                else if(i==4)this.comment5cls='input-cmt vail-error';
                this.$message.error(errInfo.msg);
              }
              else{
                if(i==0)this.comment1cls='input-cmt';
                else if(i==1)this.comment2cls='input-cmt';
                else if(i==2)this.comment3cls='input-cmt';
                else if(i==3)this.comment4cls='input-cmt';
                else if(i==4)this.comment5cls='input-cmt';
              }
          }
      }
      
      if(type==0 || type==1 || type===2){
          let h = this.measure.highValueRange*1;
          let l = this.measure.lowValueRange*1;
          let fv = 0;
          this.lowLimitcls = 'grid';//('has-error');
          if(this.monitor.minMonitor){
              errInfo = this.checkNumber(this.$t('energy.enedata133'),this.monitor.low,l,h);
              if(errInfo.err>0){
                err = errInfo.err;
                this.lowLimitcls = 'grid has-error';
                this.$message.error(errInfo.msg);
              }
          }

          this.highLimitcls = 'grid';//('has-error');
          if(this.monitor.maxMonitor){
              errInfo = this.checkNumber(this.$t('energy.enedata132'),this.monitor.high,l,h);
              if(errInfo.err>0){
                err = errInfo.err;
                this.highLimitcls = 'grid has-error';
                this.$message.error(errInfo.msg);
              }
          }

          if(this.monitor.minMonitor && this.monitor.maxMonitor){
              if(this.monitor.high*1 < this.monitor.low*1){
                err = 1;
                this.$message.error(this.$t('energy.enedata374'));
                this.lowLimitcls='grid has-error';
                this.highLimitcls='grid has-error';
                return;
              }
              h = this.monitor.high - this.monitor.low;
              fv = 1;
          }


          this.intvlcls = 'grid';//('has-error');
          if(this.monitor.minMonitor && !this.monitor.maxMonitor){
              h = this.monitor.low;
              fv = 1;
          }else if(!this.monitor.minMonitor && this.monitor.maxMonitor){
              h = this.monitor.high;
              fv = 1;
          }

          if(fv>0){
             errInfo = this.checkNumber(this.$t('energy.enedata134'),this.monitor.fixed*1,0,h);
              if(errInfo.err>0){
                err = errInfo.err;
                this.intvlcls = 'grid has-error';
                this.$message.error(errInfo.msg);
              }
          }
      }else if(type==23){
          this.lowLimitcls = 'grid';//('has-error');
          if(this.monitor.minMonitor){
              errInfo = this.checkNumber(this.$t('energy.enedata119'),this.monitor.lowLimit,0,99999);
              if(errInfo.err>0){
                err = errInfo.err;
                this.lowLimitcls = 'grid has-error';
                this.$message.error(errInfo.msg);
              }
          }

          this.highLimitcls = 'grid';//('has-error');
          if(this.monitor.maxMonitor){
              errInfo = this.checkNumber(this.$t('energy.enedata118'),this.monitor.highLimit,0,99999);
              if(errInfo.err>0){
                err = errInfo.err;
                this.highLimitcls = 'grid has-error';
                this.$message.error(errInfo.msg);
              }
          }
          if(this.monitor.minMonitor && this.monitor.maxMonitor){
              if(this.monitor.highLimit*1 < this.monitor.lowLimit*1){
                err = 1;
                this.$message.error(this.$t('energy.enedata374'));
                this.lowLimitcls='grid has-error';
                this.highLimitcls='grid has-error';
              }
          }
          this.intvlcls = 'grid';//('has-error');
          if(this.monitor.minMonitor || this.monitor.maxMonitor){
              errInfo = this.checkNumber(this.$t('energy.enedata120'),this.monitor.limitMonitoringInterval,1,86400);
              if(errInfo.err>0){
                err = errInfo.err;
                this.intvlcls = 'grid has-error';
                this.$message.error(errInfo.msg);
              }
          }
      }
      // else if(type==13){
      //   if(this.measure.maintenance && ""===this.measure.presentValue){

      //   }
      // }


      if(err>0)
          return;

      let param     = null;
      
      switch(type){
        case 0:
        case 1:
        case 2:
          param = {
              action:           2,
              clientId:         clientId,
              sitegrId:         sitegrId,
              siteId:           siteId,
              tenantId:         0,
              equipmentId:      pgrId,
              equipmentName:    this.signal.eqName,
              signalId:         signalId,
              signalName:       this.signalName,
              objectType:       type,
              voiceType:        this.sound,
              maintenance:      this.measure.maintenance,
              presentValue:     this.measure.presentValue,
              maxMonitor:       this.monitor.maxMonitor,
              minMonitor:       this.monitor.minMonitor,
              highLimit:        this.monitor.high,
              lowLimit:         this.monitor.low,
              fixed:            this.monitor.fixed,
              maxValue:         this.monitor.max,
              minValue:         this.monitor.min,
              comment:          this.comment,
              pointType:        this.signal.kind,
          }
          break;
        case 3:
        case 4:
        case 5:
          param = {
              action:            2,
              clientId:         clientId,
              sitegrId:         sitegrId,
              siteId:           siteId,
              tenantId:         0,
              equipmentId:      pgrId,
              equipmentName:    this.signal.eqName,
              signalId:         signalId,
              signalName:       this.signalName,
              objectType:       type,
              voiceType:        this.sound,
              maintenance:      this.measure.maintenance,
              presentValue:     this.measure.presentValue,
              comment:          this.comment,
              pointType:        this.signal.kind,
          }

          if(type==3){
              if(this.monitor.cumulativeTime!=null){
                  param.cumulativeTimeEnable	   = this.monitor.cumulativeTime.over;
                  param.cumulativeTimeValue      = this.monitor.cumulativeTime.overCount;
                  param.cumulativeTimeLimit      = this.monitor.cumulativeTime.max;
              }
              if(this.monitor.continuousTime!=null){
                  param.continuousTimeEnable     = this.monitor.continuousTime.over;
                  param.continuousTimeValue      = this.monitor.continuousTime.overCount;
                  param.continuousTimeLimit      = this.monitor.continuousTime.max;
              }

              if(this.monitor.statusChangeTimes!=null){
                  param.statusChangeTimesEnable  = this.monitor.statusChangeTimes.over;
                  param.statusChangeTimesValue   = this.monitor.statusChangeTimes.overCount;
                  param.statusChangeTimesLimit   = this.monitor.statusChangeTimes.max;
              }

              if(this.monitor.alertTimes!=null){
                  param.alertTimesEnable         = this.monitor.alertTimes.over;
                  param.alertTimesValue          = this.monitor.alertTimes.overCount;
                  param.alertTimesLimit          = this.monitor.alertTimes.max;
              }

              if(this.monitor.alertTime!=null){
                  param.alertTimeEnable          = this.monitor.alertTime.over;
                  param.alertTimeValue           = this.monitor.alertTime.overCount;
                  param.alertTimeLimit           = this.monitor.alertTime.max;
              }
          }else{
              if(this.cos!=null){
                  param.cosEnable                = this.cos.cosCondition;
                  param.cosValue                 = this.cos.time;  
              }
          }
           
          break;
        case 13:
        case 14:
        case 19:
          param = {
              action:                   2,
              clientId:         clientId,
              sitegrId:         sitegrId,
              siteId:           siteId,
              tenantId:         0,
              equipmentId:      pgrId,
              equipmentName:    this.measure.eqName,
              signalId:         signalId,
              signalName:       this.signalName,
              objectType:       type,
              maintenance:      this.measure.maintenance,
              presentValue:     this.measure.presentValue,
              comment:          this.comment,
              pointType:        this.signal.kind,
          }
          break;
        case 23:  
          param =  {
              action:           2,
              clientId:         clientId,
              sitegrId:         sitegrId,
              siteId:           siteId,
              tenantId:         0,
              equipmentId:      pgrId,
              equipmentName:    this.signal.eqName,
              signalId:         signalId,
              signalName:       this.signalName,
              objectType:       type,
              voiceType:        this.sound,
              maintenance:      this.measure.maintenance,
              presentValue:     this.measure.presentValue,
              displayType:      this.disp,
              maxMonitor:       this.monitor.maxMonitor,
              minMonitor:       this.monitor.minMonitor,
              highLimit:        this.monitor.highLimit,
              lowLimit:         this.monitor.lowLimit,
              limitMonitoringInterval:this.monitor.limitMonitoringInterval,
              comment:          this.comment
          }
          break;
      }
      
      param.pvTrendInterval = this.measure.pvTrendInterval;
      console.log('UpdateEnergyEquipmentDetail',param);

      this.$confirm({
      title:this.$t("energy.enedata184"),
      centered: true,
      onOk: () => {
          this.loading = true;
          updateEnergyEquipmentDetail(param) .then((res) => {
                  console.log("updateEnergyEquipmentDetail  res",res);
                  this.loading = false;
                  if (res.errorCode == "00") {
                      this.$message.success(res.msg);
                      this.initial();
                  } else  {//if (res.errorCode == "02")
                      this.$message.error(res.msg);
                  }          
              }).catch((err) => {
                  console.log(err);
                  this.loading = false;
              });
          }
      });
    },
    handleChange(tag){
        // if(tag==1){
        //     this.monitor.maxMonitor = !this.monitor.maxMonitor;
        // }else if(tag==2){
        //     this.monitor.minMonitor = !this.monitor.minMonitor;
        // }else if(tag==11){
        //     this.monitor.cumulativeTime.over = !this.monitor.cumulativeTime.over;
        // }else if(tag==12){
        //     this.monitor.continuousTime.over = !this.monitor.continuousTime.over;
        // }else if(tag==13){
        //     this.monitor.statusChangeTimes.over = !this.monitor.statusChangeTimes.over;
        // }else if(tag==14){
        //     this.monitor.alertTime.over = !this.monitor.alertTime.over;
        // }else if(tag==19){
        //     this.monitor.alertTimes.over = !this.monitor.alertTimes.over;
        // }else if(tag==13){
        //     this.cos.cosCondition = !this.cos.cosCondition;
        // }else if(tag==0){
        //     if(this.measure.maintenance){
        //         this.measure.maintenance = false;
        //     }else{
        //         this.measure.maintenance = true;
        //     }
        //     console.log("handleChange>>"+this.measure.maintenance);
        // }
    },
    handleFlush(){
      console.log("handleFlush");
      this.handleLoadData();
    },
    handleLoadData(){
        this.loading = true;
        let pgrId = this.$route.query.pgrId;
        let iconNo = this.$route.query.iconNo;
        let type = this.$route.query.kind;
        type = parseInt(type);
        let signalId = this.$route.query.signalId;
        console.log("设备ID:"+pgrId+" 信号类型:"+type+" 信号ID:"+signalId); 
        getEnergySignalDetail(pgrId,type,signalId)
            .then((res) => {
            console.log("res>>>",res);
            switch(type){
              case 0:
              case 1:
              case 2:
                this.signal = res.data.analogData;
                this.measure = this.signal.measureInfo;
                break;
              case 3:
              case 4:
              case 5:
                this.signal = res.data.digitalData;
               if(this.signal.kind==4){
                  this.measure = this.signal.alertInfo;
                  this.cos = null;
                }else if(this.signal.kind==1){
                  this.measure = this.signal.statusInfo;
                  this.cos = null;
                }else if(this.signal.kind==2){
                  this.measure = this.signal.operationInfo;
                  this.cos = {
                    time:this.signal.time,
                    cosCondition:this.signal.cosCondition	
                  };
                }else{
                  this.measure = null;
                  this.cos = null;
                }
                
                break;
              case 13:
              case 14:
              case 19:
                this.signal = res.data.multiData;
                this.measure = this.signal.measureInfo;
                this.measure.presentValue = this.measure.presentValue+"";
                break;
              case 23:  
                this.signal = res.data.accumData;
                this.measure = this.signal.measureInfo;
               break;
            }
            console.log("signal>>>",this.signal);
            if(this.signal==null || this.measure == null){
              this.loading = false;
              return;
            }
            this.signalName = this.signal.signalName;
            this.monitor = this.signal.monitor;
            this.trend = false;
            this.signalType = type;
            console.log("measure>>>",this.measure)
            console.log("monitor>>>",this.monitor)
            console.log("signalType>>>",this.signalType)
            // console.log("trendDatas>>>",this.signal.trendData)
            this.comment=this.signal.comment;   
            if(type==23)
              this.disp = this.measure.displayType+"";
            if(type!=13 && type != 14 && type != 15)
              this.sound = this.measure.voiceType+"";

            if(this.signal.trendData!=null){
              this.trend = true;
              this.drawLine(this.signal.trendData, type,this.signal.tagPoint,this.signal.signalType,this.monitor);
                 //实现自适应部分
               window.addEventListener("resize", () => this.chartResize());
            } else {
              const cm = document.getElementById("chart-main");
              if(cm!=null){
                cm.style.display="none";
              }
              this.trend = false;
            }
            //是否设置了上下限，更新颜色标志 2022-5-11 by li_yj
            if(this.monitor!=null){
              if(this.monitor.maxMonitor){
                if(this.measure!=null && this.measure.presentValue>this.monitor.high){
                  this.colorFlag = 1;
                }
              }
              if(this.monitor.minMonitor){
                if(this.measure!=null && this.measure.presentValue<this.monitor.low){
                  this.colorFlag = 2;
                }
              }
            }
            // console.log("trend",this.trend);
            this.initial();
            this.loading = false;
        }).catch((err) => {
            console.log(err);
            this.loading = false;
        });
    },
    clearComment(){
        console.log("clearComment");
        this.$confirm({
            title:this.$t("energy.enedata140"),
            centered: true,
            onOk: () => {
                 this.comment = ["","","","",""];
            }
        });                
    },
    chartResize(){
        let o = document.getElementById(this.signalChart);
        if(o!=null){
            this.$echarts.init(document.getElementById(this.signalChart)).resize();
        }
    },
    getMarkLineOption(type){
        let mark = {   
              symbol:"none",               //去掉警戒线最后面的箭头
              name:this.$t('energy.enedata132'),
              silent:true,
              data : []
        };    
        let max = 0;
        let min = 0;
        let flag = 0;

        if(type==23 && this.monitor) {
            max = this.monitor.highLimit * this.measure.coefficient;
            min = this.monitor.lowLimit  * this.measure.coefficient;
        } else if((type>=0 && type<=2)  && this.monitor) {
            max = this.monitor.high ;
            min = this.monitor.low  ;
        } else {
            return {  mark,max,min,flag }
        }
        
        if(this.monitor){
            if(this.monitor.maxMonitor){
                let line = {
                    silent:true,             //鼠标悬停事件  true没有，false有
                    lineStyle:{               //警戒线的样式  ，虚实  颜色
                        type:"solid",
                        color:"red"
                    },
                    name: this.$t('energy.enedata132'),
                    yAxis: max,
                };
                
                mark.data.push(line);
                flag++;
            }

            if(this.monitor.minMonitor){
              let line ={
                    silent:true,             //鼠标悬停事件  true没有，false有
                    lineStyle:{               //警戒线的样式  ，虚实  颜色
                        type:"solid",
                        color:"green"
                    },
                    name: this.$t('energy.enedata133'),
                    yAxis: min,
                };
                mark.data.push(line);
                flag++;
            }
        }

       return {  mark,max,min,flag }
    },
    drawLine(trend,type,tag,signalType,monitor) {
      // 基于准备好的dom，初始化echarts实例
      console.log(trend);

      var signalChart = this.$echarts.init(document.getElementById(this.signalChart));
      let trendData = trend.data;
      let categories = trend.categories;
      let unit = trend.yunit+"/"+trend.xunit;
      let sname = tag + "-" + trend.name + "-"+this.$t("energy.enedata139")+"（"+unit+"）";
           
      let markLineOpt = this.getMarkLineOption(type);
      console.log('markLineOpt',markLineOpt)
      let markLine = undefined;
      if(markLineOpt.flag>0){
          markLine = markLineOpt.mark;
      }

      let chartType = "line";
      
      let titleData = {
          text: sname,
          subtext: trend.yunit,
          left: "6%",
          textStyle: {
              fontSize: 18,
              color: "#7682ce",
              fontWeight: "bold"
          }
      };
      let tooltipData = {
                  trigger: "axis",
                  subtext: trend.yunit,
                };
      let xAxisData =  {
                  type: "category",
                  data: categories
                };
      let yAxisData =  {
                    show:true,
                    axisLine:{show:true},
                    axisTick:{show:true},
                    type: 'value',
                    max:function(value){
                        console.log("max value>>",value)
                        if(markLineOpt.flag>0 && value.max<markLineOpt.max){
                          let mx = new Number(markLineOpt.max*1.1);
                          if(mx>=1)
                              value.max =  mx.toFixed(0);
                            else if(mx<1 && mx>=0.1){
                              value.max =  mx.toFixed(1);
                            }else if(mx<0.1 && mx>=0.01){
                              value.max =  mx.toFixed(2);
                            }else if(mx<0.01 && mx>0.001){
                              value.max =  mx.toFixed(3);
                            }else if(mx<0.001 && mx>0.0001){
                              value.max =  mx.toFixed(4);
                            }
                            return value.max;
                        }
                        if(isNaN(value.max)){
                            if(markLineOpt.max>0){
                                let mx = new Number(markLineOpt.max*1.1);
                                if(mx>=1)
                                  value.max =  mx.toFixed(0);
                                else if(mx<1 && mx>=0.1){
                                  value.max =  mx.toFixed(1);
                                }else if(mx<0.1 && mx>=0.01){
                                  value.max =  mx.toFixed(2);
                                }else if(mx<0.01 && mx>0.001){
                                  value.max =  mx.toFixed(3);
                                }else if(mx<0.001 && mx>0.0001){
                                  value.max =  mx.toFixed(4);
                                }
                            }else{
                                value.max = 1;
                            }
                        } else if(value.max<0){
                            value.max =  0;
                        } else{
                            let mx = new Number(value.max*1.1);
                            if(mx>=1)
                              value.max =  mx.toFixed(0);
                            else if(mx<1 && mx>=0.1){
                              value.max =  mx.toFixed(1);
                            }else if(mx<0.1 && mx>=0.01){
                              value.max =  mx.toFixed(2);
                            }else if(mx<0.01 && mx>0.001){
                              value.max =  mx.toFixed(3);
                            }else if(mx<0.001 && mx>0.0001){
                              value.max =  mx.toFixed(4);
                            }
                            
                        }
                        return value.max;
                    },
                    min:function(value){
                      console.log("min value>>",value)
                      
                      if(isNaN(value.min)){
                          value.min = 0;
                      } else if(value.min<0){
                          let mi = new Number(value.min*1.1);
                          let mx = Math.abs(mi);
                          if(mx>=1)
                              value.min =  mi.toFixed(0);
                            else if(mx<1 && mx>=0.1){
                              value.min =  mi.toFixed(1);
                            }else if(mx<0.1 && mx>=0.01){
                              value.min =  mi.toFixed(2);
                            }else if(mx<0.01 && mx>0.001){
                              value.min =  mi.toFixed(3);
                            }else if(mx<0.001 && mx>0.0001){
                              value.min =  mi.toFixed(4);
                            }
                          // value.min =  value.min*1.1;
                      } else {
                          value.min = 0;
                      }
                      return value.min
                    },
                };
      
      // let toolboxData = {
      //        feature: {
      //                   saveAsImage: {}
      //                 }
      //           };
      let option;



      switch(type){
          case 0:
          case 1:
          case 2:
          case 23:
            
              option = {
                title: titleData,
                tooltip: tooltipData,
                toolbox: {
                  show: true,
                  orient: 'horizontal',
                  left: 'right',
                  top: 'top',
                  feature: {
                    mark: { show: true },
                    dataView: { show: false, readOnly: false },
                    magicType: { show: true, type: ['line', 'bar'] },
                    restore: { show: true },
                    saveAsImage: { show: true}
                  }
                },
                legend:  {
                    data:[trend.name]
                },
                // x轴配置
                xAxis:xAxisData,
                // y轴配置
                yAxis: yAxisData,
                series: [                  
                  {
                    name: trend.name,
                    data: trendData,
                    type: chartType,
                    smooth: true,
                    color: "#7682CE",

                    //添加上下限警戒线 2022-5-11 by li_yj
                    markLine,
                  }
                ]
              };
            break;
          case 3:
          case 4:
          case 5:
          case 13:
          case 14:
          case 19:
            unit = trend.xunit;
            sname = tag + "-" + trend.name + "-"+this.$t("energy.enedata139")+"（"+unit+"）";
            option = {
                title: {
                    text: sname,
                    textStyle: {
                      fontSize: 18,
                      color: "#7682ce",
                      fontWeight: "bold"
                    }
                },
                tooltip: {
                  trigger: "axis",
                  formatter: '{b} : <br/> {a}: {c}'
                },
                toolbox: {
                  show: true,
                  orient: 'horizontal',
                  left: 'right',
                  top: 'top',
                  feature: {
                    mark: { show: true },
                    dataView: { show: false, readOnly: false },
                    magicType: { show: true, type: ['line', 'bar'] },
                    restore: { show: true },
                    saveAsImage: { show: true}
                  }
                },
                legend:  {
                    data:[trend.name]
                },
                grid: {
                    left: '3%',
                    right: '4%',
                    bottom: '3%',
                    containLabel: true
                },
                xAxis: {
                    type: 'category',
                    data: trend.categories
                },
                yAxis: {
                    type: 'category',
                    data: trend.stepLineYAxis
                },
                series: [
                    {
                        name: trend.name,
                        type: 'line',
                        step: 'start',
                        data: trend.stepLineData,
                        color: "#7682CE",
                    }
                ]
            };
            break;
        }
        console.log("option>>",option);
        // 使用刚指定的配置项和数据显示图表。
        option && signalChart.setOption(option);
    },
    moment
  },
  computed:{
      // 卡片图标根据对应iocnNO加载对应图标
    getImages: function () {
      if(this.$route.query.iconNo){
          let iconNo = this.$route.query.iconNo;
          return require("../../../../public/images/facility/" + iconNo + ".png");
      }else{
          return require("../../../../public/images/facility/0.png");
      }
      
    },
     signalChart() {
        return "signalChart" + Math.floor((Math.random() * 10000) + 1);
    },
  },
  destroyed(){
    window.removeEventListener("resize", () => this.chartResize());
  },
  components: {
    "c-table":commontTable,
  }
};
</script>

<style scoped>
ul, li {
  list-style: none;
  margin: 0;
}
.hide{
    display: none;
}
.energySignalDetails {
  width: calc(100% - 40px);
  height:calc(100% - 30px);
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-shadow: 0px 0px 10px #bdbcbc;
  background: #ffffff;
  font-family: "Microsoft YaHei";
}
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 18px;
  padding: 10px 20px;
  border-bottom: 1px solid #cccdcd;
  white-space: nowrap;
  overflow: hidden;
}
.header-header {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.header-header span {
  margin-right: 30px;
}

.detailstitle {
  height: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.detailstitle-switch {
  display: flex;
  align-items: center;
  font-size: 18px;
  color: #3e3a39;
}
.detailstitle-switch span {
  margin-right: 15px;
}
.line {
  width: calc(100% + 40px);
  margin: 20px 0px 30px -20px;
  border-bottom: 1px solid #dbdcde;
}
.statistics {
  width: 100%;
  height: 230px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow:0 2px 15px #bdbcbc;
  border-radius: 3px;
  padding: 20px;
  overflow: hidden;
  margin-top: 10px;
}
.statistics-13 {
  width: 100%;
  height: 230px;
  display: flex;
  justify-content:flex-start;
  align-items: center;
  box-shadow:0 2px 15px #bdbcbc;
  border-radius: 3px;
  padding: 20px;
  overflow: hidden;
  margin-top: 10px;
}
.statistics-realTime {
  width: 350px;
  margin: 0 20px;
  display: flex;
  justify-content: space-between;
}
.circle {
  width: 150px;
  height: 150px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border: 2px solid #7682ce;
}
.circle-p {
  margin: 0;
  font-size: 30px;
  color: #7682ce;
}
.circle-s {
  font-size: 16px;
}
.measure-13 {
  width: 100%;
  height: 100%;
  padding: 20px 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  white-space: nowrap;
  overflow: hidden;
}
.measure {
  width: 390px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  white-space: nowrap;
  overflow: hidden;
}
.measure-header {
  font-size: 16px;
}
.measure-main-t {
  font-size: 30px;
  color: #7682ce;
  margin: 0;
}
.measure-main-t-13 {
  height: 60px;
  font-size: 30px;
  color: #7682ce;
  margin: 0;
}
.measure-main-red {
  font-size: 30px;
  color: red;
  margin: 0;
}
.measure-main-green {
  font-size: 30px;
  color: green;
  margin: 0;
}
.measure-main-b {
  font-size: 14px;
}
.measure-footer {
  display: flex;
  justify-content: space-between;
  border-top: 1px solid #dfe0e0;
  margin-top: 10px;
  padding: 10px 0px;
}
.measure-footer-p {
  font-size: 18px;
  margin: 0;
}
.measure-footer-s {
  font-size: 12px;
}
.operate {
  width: 350px;
}
.operate-13 {
  width: 100%;
}
.operate-li{
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;
  white-space: nowrap;
  overflow: hidden;
}
.operate-li-13{
  padding:10px 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-size: 16px;
  overflow: hidden;
}
.operate-li-div{
  width: 230px;
}
.operate-li-div span{
  margin-left: 10px;
}
.border{
  border-top: 1px solid #dfe0e0;
  border-bottom: 1px solid #dfe0e0;
}
.weight {
  padding: 10px 20px;
  font-size: 16px;
  border-bottom: 1px solid #dfe0e0;
}

.box{
    border-radius: 3px;
    border: 1px solid #f3f3f5;
    margin-top: 30px;
}
.box-title {
  height: 50px;
  /* width: calc(100%-60px); */
  background: #f3f3f5;
  line-height: 50px;
  font-size: 15px;
  padding: 0 30px;
  white-space: nowrap;
  overflow: hidden;
}
.box-list {
  width: 100%;
  height: calc(20rem);
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  padding: 20px 20px 20px 20px;
  overflow: auto;
}
.box-signal{
    height: 72px;
    width: 160px;
    border-radius: 3px;
    border: 1px solid #f5f5f5;
    margin: 3px;
}
.box-head{
    display: flex;
    justify-content: flex-start;
}
.box-body{
    display: flex;
    justify-content: flex-end;
}
.box-value{
    font-size: 18px;
    margin: 5px;
}
.box-name{
    font-size: 18px;
    margin: 5px;
}
.box-unit{
    font-size: 18px;
    text-align: right;
    margin: 5px;
}
.box-icon{
    width:  24px;
    height: 24px;
    margin: 5px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
}
.box-icon-0{
    background-image: url('../../../../public/images/facility/0.png');
}
.box-icon-1{
    background-image: url('../../../../public/images/facility/0.png');
}
.box-icon-2{
    background-image: url('../../../../public/images/facility/0.png');
}
.box-icon-3{
    background-image: url('../../../../public/images/facility/3.png');
}
.box-icon-4{
    background-image: url('../../../../public/images/facility/3.png');
}
.box-icon-5{
    background-image: url('../../../../public/images/facility/3.png');
}
.box-icon-13{
    background-image: url('../../../../public/images/facility/3.png');
}
.box-icon-14{
    background-image: url('../../../../public/images/facility/3.png');
}
.box-icon-15{
    background-image: url('../../../../public/images/facility/3.png');
}
.box-icon-23{
    background-image: url('../../../../public/images/facility/0.png');
}
/* .box-grid {
  display: flex;
  align-items: center;
  width: 45%;
  color:rgba(0, 0, 0, 0.65);
}
.box-grid-s {
  padding: 0 8px;
} */
.monitor-title {
  height: 50px;
  background: #f3f3f5;
  line-height: 50px;
  font-size: 15px;
  padding: 0 30px;
  display: flex;
  justify-content: space-between;
  white-space: nowrap;
  overflow: hidden;
}
.monitor-title-analog {
  height: 50px;
  background: #f3f3f5;
  line-height: 50px;
  font-size: 15px;
  padding: 0 30px;
  display: flex;
  justify-content: space-left;
  white-space: nowrap;
  overflow: hidden;
}
.monitor-title-analog-icon{
  margin-left:30px;
  padding-top:30;
}
.monitor-li {
  display: flex;
  align-items: center;
  font-size: 14px;
  padding: 10px;
  border-bottom: 1px solid #f3f3f5;
  white-space: nowrap;
  overflow: hidden;
}
.monitor-li:last-child{
    border-bottom: 0;
}

.monitor-li-binary {
  font-size: 14px;
  padding: 10px;
  border-bottom: 1px solid #f3f3f5;
  white-space: nowrap;
  overflow: hidden;
}
.monitor-li-binary:last-child{
    border-bottom: 0;
}

.grid {
  width: 280px; 
  display: flex;
  align-items: center;
}
.grid-two {
  width: 200px;  
  display: flex;
  align-items: center;
}
.register {
  display: flex;
}
.register-ul {
  width: 50%;
  padding: 0 10px;
  margin: 0;
}
.register-li {
  height: 53px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 15px;
  border-bottom: 1px solid #dfe0e0;
  white-space: nowrap;
  overflow: hidden;
}
.register-li:last-child{
    border-bottom: 0;
}
.register-li-item {
  display: flex;
}
.register-li-item-p {
  padding: 0px 10px;
  margin: 0;
}
.register-li-img {
  width: 40px;
  height: 40px;
}
.footer {
  display: flex;
  justify-content: center;
  padding-top: 10px;
}
.footer button {
  width: 120px;
  margin: 0 20px;
}
.content {
  width: 100%;
  margin: 10px 0;
}
.input-width {
  width: 120px;
  margin-right: 15px;
}
.input-cmt{width:320px;border:none;}
.input-cmt-sn{
    height:32px;line-height:32px;margin-right:10px;
}
.ename{
  border:none;
  height:32px;
  width:360px;
  font-size:18px;
  color:#000000;
}

.vail-error{
  border:1px solid #f5222d;
}

.main{
  width: 100%;
  height: calc(100% - 68px);
  padding: 10px 20px;
  overflow: auto;
}

.box-grid{
   padding:5px;
}
.box-grid-low{
   padding:5px;margin-left:30px;border-top:1px solid #f5f5f5;
}
</style>