<template>
  <div id="baseinfo">
    <div class="baseinfo-left">
      <div class="row">
        <p v-show="initial">{{ showData.id }}    {{ showData.content }}</p>        
      </div>
      <div class="row">        
        <a-input v-model="tempContent" v-show="alter"  ref="input"/>
      </div>      
    </div>
    <div class="baseinfo-right">
      <a-icon
        type="form"
        v-show="initial"
        @click="StateChanges"
        :style="{ fontSize: '20px', color: '#7682CE' }"
      />
      <a-button v-show="alter" @click="Cancel" type="primary" style="width: 80px" ghost>取消</a-button>
      <a-button v-show="alter" @click="Confirm" type="primary" style="width: 80px" >确认</a-button>
    </div>
  </div>
</template>

<script>
export default {
  //父窗口传入参数
  props: {
    showData: {
      type: Object,
      default: () => ({}),
    },
  },
  //本地参数
  data() {
    return {
      initial: true,
      alter: false,
      tempContent : "",
    };
  },
  methods: {
    StateChanges() {
      this.initial = !this.initial;
      this.alter = !this.alter;
      setTimeout(() => {
        this.$refs.input.focus();
      }, 5);
    },
    Confirm(){
      this.initial = !this.initial;
      this.alter = !this.alter;
      this.showData.content=this.tempContent+"";
      this.$emit('transferData',this.tempContent);//返回数据至父组件
    },
    Cancel() {
      this.initial = !this.initial;
      this.alter = !this.alter;
    },
  },
};
</script>

<style scoped>
#baseinfo {
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  /*内边距*/
  padding: 20px 20px 20px 20px; 
  border-bottom: 1px solid #dedfdf;
  border-radius: 3px;
  /* 阴影 */
  /* box-shadow: 0px 0px 10px #bdbcbc; */ 
  /* margin-bottom: 20px; */
  overflow: hidden;
}
.baseinfo-left {
  display: flex;
}
p {
  margin-bottom: 0;
}
.row {
  margin-right: 40px;
}
.row span {
  font-size: 13px;
  color: #898989;
  white-space: nowrap;
}
.row p {
  font-size: 16px;
  white-space: nowrap;
}
.baseinfo-right {
  display: flex;
  align-items: center;
}
button {
  width: 120px;
  margin-left: 15px;
}
</style>